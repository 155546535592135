
.header{

  background-color:#000000;
  backdrop-filter: blur(0px);
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;
  display: flex;
}

.menu{
  width: 60%;
}

.navbar-logo img {
    height: 30px; /* ou la taille qui convient à votre design */
    width: auto;
  }
  
  .navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
  
  }

  

/* src/components/Navbar.css */
.navbar {
    background-color: #000000; /* Un fond clair, adapté au site de référence */
    padding-left: 2rem;
    padding-right: 2rem;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    height: auto;
    width: 100%;
    z-index: 1000;
  }
  
  .nav-links {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center; /* Centre les liens horizontalement */
    align-items: center;
  }
  
  .nav-links li {
    margin: 0 20px;
  }
  
  .nav-links a {
    color: rgb(255, 255, 255);
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    transition: color 0.3s ease;
  }
  
  .nav-links a:hover {
    color: #0a4064; /* Une couleur de survol bleue, ajustez selon le style du site */
  }
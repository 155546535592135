/* src/components/Home.css */
.home {
    text-align: center;
    padding: 50px;
    background-color: #000000;
    flex-grow: 1;  /* Permet à ce composant de s'étendre pour remplir l'espace disponible */
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .home h1 {
    color: #ffffff;
  }
  
  .home p {
    color: #ffffff;
  }
  
  .home button {
    padding: 10px 20px;
    width: 200px;
    font-size: 16px;
    background-color: #007bff;
    margin-left: auto;
    margin-right: auto;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .home button:hover {
    background-color: #0056b3;
  }
  

 
  